@use 'abstracts/variables' as var;
@use 'abstracts/mixins' as mixin;

.article-content {
    @include  mixin.font-size(1.4rem, 1.4, var.$basic-font-weight);
    color: var.$basic-font-color;

    & h1 {
        @include mixin.font-size(36px, 1.1, 500);
        color: var.$dark-font-color;
        margin-top: 19px;
        margin-bottom: 38px;

        @include  mixin.md-up { font-size: 40px; }
        @include  mixin.lg-up { font-size: 44px;}
        @include  mixin.xl-up { font-size: 48px;}

    }

    & h2 {
        @include  mixin.font-size(27px, 1.1, 500);
        color: var.$dark-font-color;
        margin-top: 19px;
        margin-bottom: 19px;

        @include  mixin.md-up { font-size: 29px; }
        @include  mixin.lg-up { font-size: 33px;}
        @include  mixin.xl-up { font-size: 37px; }
    }

    & h3 {
        @include  mixin.font-size(25px, 1.1, 500);
        color: var.$dark-font-color;
        margin-top: 19px;
        margin-bottom: 19px;

        @include  mixin.md-up { font-size: 27px; }
        @include  mixin.lg-up { font-size: 31px;}
        @include  mixin.xl-up { font-size: 35px; }

    }

    & h4  {
        @include  mixin.font-size(18px, 1.2, 500);
        color: var.$dark-font-color;
        margin-top: 19px;
        margin-bottom: 8px;

        @include  mixin.md-up { font-size: 19px; }
        @include  mixin.lg-up { font-size: 20px;}
        @include  mixin.xl-up { font-size: 22px; }
        @include  mixin.xxl-up { font-size: 22px; }
    }

    & h5 {
        @include  mixin.font-size(16px, 1.2, 500);
        color: var.$dark-font-color;
        margin-top: 19px;
        margin-bottom: 6px;

        @include  mixin.md-up { font-size: 17px; }
        @include  mixin.lg-up { font-size: 18px;}
        @include  mixin.xl-up { font-size: 19px; }
        @include  mixin.xxl-up { font-size: 20px; }
    }

    & .subtitle {
        margin-bottom: 3rem;
    }

    & p, & ul, & ol, & pre, & table, & blockquote {
        margin-top: 0px;
        margin-bottom: 2.5rem;
        font-weight: 400;
    }

    & p {
        @include  mixin.font-size(1.5rem, 1.3, var.$basic-font-weight);
        color: var.$basic-font-color;

        @include mixin.xl-up {
            @include  mixin.font-size(1.7rem, 1.3, var.$basic-font-weight);
        }
    }

    & ul & ul, & ol & ol, & ul & ol, & ol & ul {
        margin-top: 0;
        margin-bottom: 0;
    }

    & ul {
        list-style-type: disc;
        margin-left: 40px;
    }

    & ol {
        list-style-type: decimal;
        margin-left: 40px;
    }


    & hr  {
        border: 1px solid;
        margin: -1px 0;
    }

    & a, & b, & i, & strong, & em, & small, & code {
        line-height: 0;
    }

    & sub, & sup {
        line-height: 0;
        position: relative;
        vertical-align: baseline;
    }

    & sup {
        top: -0.5em;
    }

    & sub {
        bottom: -0.25em;
    }

    & a {
        color: var.$accent-font-color;
    }


    & img {
        width: 100%;
        border-radius: 22px;
        margin: 35px 0;
        min-height: 25rem;
        object-fit: cover;
    }

    & img.thumb {
        margin-top: 0;
        margin-bottom: 5rem;

        @include mixin.xl-up {
            margin-bottom: 8rem;
        }

    }

    &__counter {
        padding: 2rem 0;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        gap: 1rem;

        @include mixin.md-up {
            flex-direction: row;
        }

        &-number {
            @include mixin.flex-row(1rem);
            @include mixin.flex-center-center;
            font-family: var.$secendery-font-family;
            @include mixin.font-size(11rem, 1, 400);
            color: var.$dark-font-color;

            @include mixin.md-up {
                font-size: 11rem;
            }

            &::before {
                content: "";
                display: inline-block;
                @include mixin.size(1rem, 1rem);
                background-color: var.$accent-secendery;
            }
        }

    }


  }
