// ----------------
//    LAYOUT
// ----------------

@use 'abstracts/variables' as var;
@use 'abstracts/mixins' as mixin;

.container {

    margin: 0 auto;
    padding: 0 1.5rem;
    overflow: hidden;

    @include mixin.xl-up {
        max-width: var.$container-width-xl;
        overflow: unset;
    }

}

.module {
    padding: 4rem 0;

    &--pt {
        padding-top: 4rem;
    }

    &--pt-2 {
        padding-top: 8rem;
    }

    &--pb {
        padding-bottom: 4rem;
    }

    &--pb-2 {
        padding-bottom: 8rem ;
    }

    @include mixin.lg-up{

        padding: 7rem 0;

        &--pt {
            padding-top: 7rem;
        }

        &--pt-2 {
            padding-top: 14rem;
        }

        &--pb {
            padding-bottom: 7rem;
        }

        &--pb-2 {
            padding-bottom: 14rem;
        }

    }

}



