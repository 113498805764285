// ----------------
//    FORMS
// ----------------

@use "abstracts/variables" as var;
@use "abstracts/mixins" as mixin;

.input {
  font-family: var.$main-font-family;
  @include mixin.font-size(17px, 1, 500);
  color: var.$dark-font-color;

  border-radius: 0;
  border-radius: 8px;
  border: 1px solid var.$basic-border-color;

  padding: 15px 15px;

  transition: all 0.3s linear;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px white inset !important;
  }

  &::placeholder {
    @include mixin.font-size(17px, 1, 500);
    color: rgba(15, 16, 18, 0.2);
  }

  &:focus {
    border: 1px solid #d47d50;
  }

  &--full {
    display: block;
    width: 100%;
  }

  &[required] {
    background-image: url("../img/svg/star-required.svg");
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 2.5rem;
  }
}

// Checkbox
.custom-checkbox-wrap {
  position: relative;
}

.custom-checkbox {
  position: relative;
  @include mixin.size(23px, 23px);

  & input[type="checkbox"] {
    opacity: 0;
    margin-top: -0.2px;

    &:checked + .input-checkbox {
      background: #fff;
      border-color: var.$basic-border-color;

      &::after {
        content: "";
        display: block;
        @include mixin.absolute(2px, null, null, 7px);
        @include mixin.size(8px, 13px);

        border: solid var.$dark-secendery;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }

  & .input-checkbox {
    @include mixin.absolute(0, null, null, 0);
    @include mixin.size(23px, 23px);
    background: #fff;
    border: 1px solid var.$basic-border-color;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s, border-color 0.3s;

    &--dark {
      border: 1px solid #262729;
      background-color: #191a1c;
    }
  }

  &__label {
    margin: -17px 0 0 35px;
    width: max-content;

    @include mixin.xl-up {
      margin: -17px 0 0 35px;
    }
  }

  &__wrapper {
    margin-top: 16px;
  }
}

// Form offer
.form-offer {
  width: 100%;

  &__option-list {
    @include mixin.flex-column(0.8rem);

    @include mixin.sm-up {
      @include mixin.flex-row(0.8rem);
    }
  }
  &__option-item {
    padding: 1.4rem;
    background-color: #fff;
    border-radius: 0.8rem;

    &.active {
      background-color: var.$dark-secendery;

      & .label-text {
        color: #fff;
      }
    }
  }

  & .btn {
    margin-top: 2.8rem;
    width: 100%;
    @include mixin.sm-up {
      margin-top: 3.8rem;
      width: max-content;
    }
  }
}

// Form post
.form-post {
  margin-top: 1.8rem;

  & .input {
    margin-bottom: 1.2rem;

    @include mixin.md-up {
      margin-bottom: 1.8rem;
    }
  }

  & .custom-checkbox {
    &__label {
      width: unset;
    }
  }

  & .btn {
    margin-top: 1.4rem;

    @include mixin.md-up {
      margin-top: 2.1rem;
    }
  }
}

// Newsletter form

.newsletter-form {
  position: relative;

  &__input {
    @include mixin.size(100%, 5.2rem);

    outline: none;
    border: none;
    margin: 0;
    padding: 0 6.5rem 0.2rem 1.8rem;
    border-radius: 5rem;

    font-family: var.$main-font-family;
    @include mixin.font-size(1.5rem, 1.5rem, 500);
    color: var.$basic-font-color;

    background-color: #191a1c;

    &::placeholder {
      @include mixin.font-size(1.5rem, 1.5rem, 500);
      color: #65676d;
    }
  }

  & .custom-checkbox {
    &__label {
      width: unset;
    }
  }

  &__btn {
    @include mixin.absolute(0.8rem, 0.8rem, null, null);
    @include mixin.size(3.8rem, 3.8rem);

    background: var.$accent-secendery url("../img/icons/btn-arrow--bright.svg")
      no-repeat;
    background-size: 2.2rem 1.6rem;
    background-position: 0.8rem 1.1rem;

    border-radius: 50%;
    border: none;
    cursor: pointer;
  }
}
