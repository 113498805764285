@use "layout/layout";

// Wordpress
.page-id-109 {
  & .range {
    position: relative;
    z-index: 5;
  }
  & .local-list {
    @extend .module--pt-2;
    @extend .module--pb;
  }

  & .step-to-step {
    @extend .module--pt-2;
  }
}
