@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-230px * 24));
  }
}

@keyframes textIn {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes textOut {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes gleam {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

@keyframes marquee-label-1c {
  0% {
    left: 0;
  }
  100% {
    left: -652px;
  }
}

@keyframes marquee-label-2c {
  0% {
    left: 0;
  }
  100% {
    left: -614px;
  }
}

@keyframes marquee-label-3c {
  0% {
    left: 0;
  }
  100% {
    left: -649px;
  }
}

@keyframes marquee-label-4c {
  0% {
    left: 0;
  }
  100% {
    left: -704px;
  }
}

@keyframes push-1 {
  0% {
    transform: translateY(0) scale(0);
    opacity: 0;
  }
  10% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  20% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(7rem);
  }
  65% {
    transform: translateY(7rem);
  }
  95% {
    transform: translateY(14rem);
  }
  100% {
    transform: translateY(14rem);
  }
}

@keyframes push-2 {
  0% {
    transform: translateY(0) scale(0);
    opacity: 0;
  }
  25% {
    transform: translateY(0) scale(0);
    opacity: 0;
  }
  40% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  40% {
    transform: translateY(0) scale(1);
  }
  65% {
    transform: translateY(0) scale(1);
  }
  95% {
    transform: translateY(7rem);
  }
  100% {
    transform: translateY(7rem);
  }
}

@keyframes push-3 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  25% {
    transform: scale(0);
    opacity: 0;
  }
  30% {
    transform: scale(0);
    opacity: 0;
  }
  70% {
    transform: scale(0);
    opacity: 0;
  }
  80% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}
