@use "layout/layout";
@use "abstracts/mixins" as mix;

// Wordpress

.page-id-468 {
  & .appointment {
    @include mix.lg-down {
      padding-top: 8rem;
    }
  }

  & .faq {
    @extend .module--pt-2;
  }
}
