// ----------------
//    THEMES
// ----------------

@use 'abstracts/variables' as var;

.theme {
    &--base { background-color: var.$basic-theme-bg; }
    &--dark { background-color: var.$dark-theme-bg; }
    &--bright { background-color:  var.$bright-theme-bg;}
    &--accent { background-color:  var.$accent-theme-bg;}
}
