@use "abstracts/mixins" as mixin;
@use "abstracts/variables" as var;

.list-icon {
  display: flex;
  flex-direction: column;
  gap: 12px;
  &__item {
    display: flex;
    align-items: center;
    gap: 12px;

    font-size: 15px;
    font-weight: 300;
    color: #082031;
    & .bold {
      font-weight: 500;
    }
    & .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border-radius: 200px;
      background: #d47d50;

      & svg {
        max-width: 60%;
      }
    }
  }
}
