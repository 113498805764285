// ----------------
//    TYPOGRAPHY
// ----------------

@use "abstracts/variables" as var;
@use "abstracts/mixins" as mixin;

.heading {
  &--1 {
    @include mixin.font-size(35px, 1.1, 500);
    @include mixin.md-up {
      font-size: 35px;
    }
    @include mixin.lg-up {
      font-size: 35px;
    }
    @include mixin.xl-up {
      font-size: 38px;
    }
  }

  &--2 {
    @include mixin.font-size(28px, 1.1, 500);
    @include mixin.md-up {
      font-size: 28px;
    }
    @include mixin.lg-up {
      font-size: 30px;
    }
    @include mixin.xl-up {
      font-size: 32px;
    }
  }

  &--3 {
    @include mixin.font-size(22px, 1.1, 500);
    @include mixin.md-up {
      font-size: 22px;
    }
    @include mixin.lg-up {
      font-size: 22px;
    }
    @include mixin.xl-up {
      font-size: 22px;
    }
    @include mixin.xxl-up {
      font-size: 22px;
    }
  }

  &--4 {
    @include mixin.font-size(18px, 1.2, 500);
    @include mixin.md-up {
      font-size: 19px;
    }
    @include mixin.lg-up {
      font-size: 20px;
    }
    @include mixin.xl-up {
      font-size: 22px;
    }
    @include mixin.xxl-up {
      font-size: 22px;
    }
  }

  &--5 {
    @include mixin.font-size(16px, 1.2, 500);
    @include mixin.md-up {
      font-size: 18px;
    }
  }

  &--dark {
    color: var.$dark-font-color;
  }
  &--bright {
    color: var.$bright-font-color;
  }
}

.text {
  @include mixin.font-size(14px, 1.4, var.$basic-font-weight);
  color: var.$basic-font-color;

  @include mixin.xl-up {
    @include mixin.font-size(
      var.$basic-font-size,
      var.$basic-line-height,
      var.$basic-font-weight
    );
  }

  &--small {
    @include mixin.font-size(1.2rem, 1.6rem, 400);
  }

  &--color-bright {
    color: var.$bright-font-color;
  }

  &--color-dark {
    color: var.$dark-font-color;
  }

  &--color-accent {
    color: var.$accent-font-color;
  }

  &--gradniet-accent {
    background: linear-gradient(90deg, #382b28 0%, #eb5633 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    text-shadow: -3px -8px 50px rgba(239, 84, 48, 0.5);
  }

  &--500 {
    font-weight: 500;
  }
  &--600 {
    font-weight: 600;
  }
}

.rich-text {
  &--basic {
    @include mixin.flex-column(16px);

    @include mixin.md-up {
      @include mixin.flex-column(30px);
    }
  }
}
