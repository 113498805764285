@use "abstracts/variables" as var;
@use "abstracts/mixins" as mixin;

.hero-contact {
  background-color: #fff;
  padding: 80px 0;

  & .container {
    @include mixin.xl-down {
      padding: 0;
    }
  }

  & .grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 60px;

    @include mixin.xl-up {
      grid-template-columns: 1fr 400px;
      justify-content: space-between;
      align-items: center;
      gap: 100px;
    }
  }

  & .header {
    max-width: 500px;
    padding: 0 15px 0 15px;

    @include mixin.xl-up {
      padding: 14rem 1.5rem 14rem 1.5rem;
    }
  }

  &__form {
    @include mixin.xl-down {
      padding: 0 1.5rem;
    }
  }

  & .rich-text {
    margin-top: 25px;
  }
}
