@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-5520px);
  }
}
@keyframes textIn {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes textOut {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}
@keyframes gleam {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@keyframes marquee-label-1c {
  0% {
    left: 0;
  }
  100% {
    left: -652px;
  }
}
@keyframes marquee-label-2c {
  0% {
    left: 0;
  }
  100% {
    left: -614px;
  }
}
@keyframes marquee-label-3c {
  0% {
    left: 0;
  }
  100% {
    left: -649px;
  }
}
@keyframes marquee-label-4c {
  0% {
    left: 0;
  }
  100% {
    left: -704px;
  }
}
@keyframes push-1 {
  0% {
    transform: translateY(0) scale(0);
    opacity: 0;
  }
  10% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  20% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(7rem);
  }
  65% {
    transform: translateY(7rem);
  }
  95% {
    transform: translateY(14rem);
  }
  100% {
    transform: translateY(14rem);
  }
}
@keyframes push-2 {
  0% {
    transform: translateY(0) scale(0);
    opacity: 0;
  }
  25% {
    transform: translateY(0) scale(0);
    opacity: 0;
  }
  40% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  40% {
    transform: translateY(0) scale(1);
  }
  65% {
    transform: translateY(0) scale(1);
  }
  95% {
    transform: translateY(7rem);
  }
  100% {
    transform: translateY(7rem);
  }
}
@keyframes push-3 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  25% {
    transform: scale(0);
    opacity: 0;
  }
  30% {
    transform: scale(0);
    opacity: 0;
  }
  70% {
    transform: scale(0);
    opacity: 0;
  }
  80% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}
.btn {
  display: inline-block;
  position: relative;
  text-decoration: none;
  font-family: "Helvetica", sans-serif;
  min-width: fit-content;
  padding: 18px 18px;
  border-radius: 0;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 32px;
  line-height: 1;
  font-weight: 600;
}
@media (min-width: 1250px) {
  .btn {
    font-size: 15px;
    line-height: 1;
    font-weight: 400;
    padding: 21px 27px;
  }
}
.btn--full {
  display: block;
  text-align: center;
  width: 100%;
}
.btn--max-content {
  width: max-content;
}
.btn--bright {
  background-color: #fff;
}
.btn--transparent {
  border: 1px solid #e0d7d5;
  background-color: none;
  width: max-content;
}
.btn--accent {
  background-color: #d47d50;
}
.btn--text-accent {
  color: #d47d50;
}
.btn--text-bright {
  color: #fff;
}
.btn--text-dark {
  color: #0f1012;
}
.btn--arrow {
  padding: 1.8rem 5rem 1.8rem 1.8rem;
}
@media (min-width: 1250px) {
  .btn--arrow {
    padding: 2.1rem 6.1rem 2.1rem 2.7rem;
  }
}
.btn--arrow::after {
  content: "";
  display: inline-block;
  width: 2.9rem;
  height: 2.9rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 0.8rem;
  transform: translateY(-50%);
}
@media (min-width: 1250px) {
  .btn--arrow::after {
    width: 4rem;
    height: 4rem;
  }
}
.btn--arrow-bright::after {
  background: #fff url("../img/icons/btn-arrow--accent.svg") no-repeat center center;
}
.btn--arrow-dark::after {
  background: #0f1012 url("../img/icons/btn-arrow--bright.svg") no-repeat center center;
}
.btn--arrow-accent::after {
  background: #d47d50 url("../img/icons/btn-arrow--bright.svg") no-repeat center center;
}
.btn--arrow-w-border::after {
  background: url("../img/icons/btn-arrow--dark.svg") no-repeat center center;
  border: 1px solid #e0d7d5;
}
@media (min-width: 1250px) {
  .btn--arrow-w-border::after {
    background-size: unset;
  }
}
.btn--arrow-light {
  background: none;
  padding: 0 4.9rem 0 0;
  line-height: 2.9rem;
}
@media (min-width: 1250px) {
  .btn--arrow-light {
    line-height: 4rem;
    padding: 0 6.5rem 0 0;
  }
}
.btn--arrow-left {
  padding: 0 0 0 3.9rem;
}
@media (min-width: 1250px) {
  .btn--arrow-left {
    padding: 0 0 0 5.3rem;
  }
}
.btn--arrow-left::after {
  position: absolute;
  top: 50%;
  left: 0;
}
.btn--arrow-only {
  padding: 0;
  width: 2.9rem;
  height: 2.9rem;
}
@media (min-width: 1250px) {
  .btn--arrow-only {
    width: 4rem;
    height: 4rem;
  }
}
.btn--arrow-only::after {
  right: 0;
  background-size: 1.4rem;
}

.btn-main-navigation {
  width: 3.2rem;
  height: 3.2rem;
  background: url("../img/icons/btn-main-navigation.svg") no-repeat center center;
}
.btn-main-navigation.open-menu {
  background: url("../img/icons/close-menu.svg") no-repeat center center;
}

.link, .link--dark, .link--bright, .link--accent {
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 1.4;
  font-weight: 400;
}
@media (min-width: 1250px) {
  .link, .link--dark, .link--bright, .link--accent {
    font-size: 15px;
    line-height: 21px;
    font-weight: 400;
  }
}
.link--dark, .link--bright, .link--accent {
  transition: 0.3s color;
}
.link--dark:hover, .link--bright:hover, .link--accent:hover {
  color: #d47d50;
}
.link--dark {
  color: #0f1012;
}
.link--bright {
  color: #fff;
}
.link--accent {
  color: #d47d50;
  text-decoration: underline;
}
.link-has-children::after {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  position: relative;
  top: 2px;
  right: 0;
  bottom: 0;
  left: 7px;
  background: url("../img/icons/link-arrow-black.svg") no-repeat;
}

.back-link {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  font-size: 1.4rem;
  line-height: 2.3rem;
  font-weight: 600;
  color: #0f1012;
}
@media (min-width: 768px) {
  .back-link {
    font-size: 1.7rem;
    line-height: 2.8rem;
    font-weight: 600;
  }
}
.back-link::before {
  content: "";
  display: inline-block;
  transform: rotate(180deg);
  background: #d47d50 url("../img/icons/btn-arrow--bright.svg") no-repeat center center;
  background-size: 1.2rem 1.2rem;
  border-radius: 5rem;
  width: 2.2rem;
  height: 2.2rem;
}
@media (min-width: 768px) {
  .back-link::before {
    background-size: 1.4rem 1.4rem;
    width: 2.8rem;
    height: 2.8rem;
  }
}

.heading--1 {
  font-size: 35px;
  line-height: 1.1;
  font-weight: 500;
}
@media (min-width: 768px) {
  .heading--1 {
    font-size: 35px;
  }
}
@media (min-width: 992px) {
  .heading--1 {
    font-size: 35px;
  }
}
@media (min-width: 1250px) {
  .heading--1 {
    font-size: 38px;
  }
}
.heading--2 {
  font-size: 28px;
  line-height: 1.1;
  font-weight: 500;
}
@media (min-width: 768px) {
  .heading--2 {
    font-size: 28px;
  }
}
@media (min-width: 992px) {
  .heading--2 {
    font-size: 30px;
  }
}
@media (min-width: 1250px) {
  .heading--2 {
    font-size: 32px;
  }
}
.heading--3 {
  font-size: 22px;
  line-height: 1.1;
  font-weight: 500;
}
@media (min-width: 768px) {
  .heading--3 {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .heading--3 {
    font-size: 22px;
  }
}
@media (min-width: 1250px) {
  .heading--3 {
    font-size: 22px;
  }
}
@media (min-width: 1600px) {
  .heading--3 {
    font-size: 22px;
  }
}
.heading--4 {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
}
@media (min-width: 768px) {
  .heading--4 {
    font-size: 19px;
  }
}
@media (min-width: 992px) {
  .heading--4 {
    font-size: 20px;
  }
}
@media (min-width: 1250px) {
  .heading--4 {
    font-size: 22px;
  }
}
@media (min-width: 1600px) {
  .heading--4 {
    font-size: 22px;
  }
}
.heading--5 {
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500;
}
@media (min-width: 768px) {
  .heading--5 {
    font-size: 18px;
  }
}
.heading--dark {
  color: #0f1012;
}
.heading--bright {
  color: #fff;
}

.text {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
  color: #787d8e;
}
@media (min-width: 1250px) {
  .text {
    font-size: 15px;
    line-height: 21px;
    font-weight: 400;
  }
}
.text--small {
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 400;
}
.text--color-bright {
  color: #fff;
}
.text--color-dark {
  color: #0f1012;
}
.text--color-accent {
  color: #d47d50;
}
.text--gradniet-accent {
  background: linear-gradient(90deg, #382b28 0%, #eb5633 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: -3px -8px 50px rgba(239, 84, 48, 0.5);
}
.text--500 {
  font-weight: 500;
}
.text--600 {
  font-weight: 600;
}

.rich-text--basic {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
@media (min-width: 768px) {
  .rich-text--basic {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}

.theme--base {
  background-color: #fff;
}
.theme--dark {
  background-color: #0f1012;
}
.theme--bright {
  background-color: #f2f2f4;
}
.theme--accent {
  background-color: #d47d50;
}

.input {
  font-family: "Helvetica", sans-serif;
  font-size: 17px;
  line-height: 1;
  font-weight: 500;
  color: #0f1012;
  border-radius: 0;
  border-radius: 8px;
  border: 1px solid #e0d7d5;
  padding: 15px 15px;
  transition: all 0.3s linear;
}
.input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px white inset !important;
}
.input::placeholder {
  font-size: 17px;
  line-height: 1;
  font-weight: 500;
  color: rgba(15, 16, 18, 0.2);
}
.input:focus {
  border: 1px solid #d47d50;
}
.input--full {
  display: block;
  width: 100%;
}
.input[required] {
  background-image: url("../img/svg/star-required.svg");
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 2.5rem;
}

.custom-checkbox-wrap {
  position: relative;
}

.custom-checkbox {
  position: relative;
  width: 23px;
  height: 23px;
}
.custom-checkbox input[type=checkbox] {
  opacity: 0;
  margin-top: -0.2px;
}
.custom-checkbox input[type=checkbox]:checked + .input-checkbox {
  background: #fff;
  border-color: #e0d7d5;
}
.custom-checkbox input[type=checkbox]:checked + .input-checkbox::after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 7px;
  width: 8px;
  height: 13px;
  border: solid #0f1012;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.custom-checkbox .input-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 23px;
  height: 23px;
  background: #fff;
  border: 1px solid #e0d7d5;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s, border-color 0.3s;
}
.custom-checkbox .input-checkbox--dark {
  border: 1px solid #262729;
  background-color: #191a1c;
}
.custom-checkbox__label {
  margin: -17px 0 0 35px;
  width: max-content;
}
@media (min-width: 1250px) {
  .custom-checkbox__label {
    margin: -17px 0 0 35px;
  }
}
.custom-checkbox__wrapper {
  margin-top: 16px;
}

.form-offer {
  width: 100%;
}
.form-offer__option-list {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
@media (min-width: 576px) {
  .form-offer__option-list {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
  }
}
.form-offer__option-item {
  padding: 1.4rem;
  background-color: #fff;
  border-radius: 0.8rem;
}
.form-offer__option-item.active {
  background-color: #0f1012;
}
.form-offer__option-item.active .label-text {
  color: #fff;
}
.form-offer .btn {
  margin-top: 2.8rem;
  width: 100%;
}
@media (min-width: 576px) {
  .form-offer .btn {
    margin-top: 3.8rem;
    width: max-content;
  }
}

.form-post {
  margin-top: 1.8rem;
}
.form-post .input {
  margin-bottom: 1.2rem;
}
@media (min-width: 768px) {
  .form-post .input {
    margin-bottom: 1.8rem;
  }
}
.form-post .custom-checkbox__label {
  width: unset;
}
.form-post .btn {
  margin-top: 1.4rem;
}
@media (min-width: 768px) {
  .form-post .btn {
    margin-top: 2.1rem;
  }
}

.newsletter-form {
  position: relative;
}
.newsletter-form__input {
  width: 100%;
  height: 5.2rem;
  outline: none;
  border: none;
  margin: 0;
  padding: 0 6.5rem 0.2rem 1.8rem;
  border-radius: 5rem;
  font-family: "Helvetica", sans-serif;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: #787d8e;
  background-color: #191a1c;
}
.newsletter-form__input::placeholder {
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: #65676d;
}
.newsletter-form .custom-checkbox__label {
  width: unset;
}
.newsletter-form__btn {
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  width: 3.8rem;
  height: 3.8rem;
  background: #d47d50 url("../img/icons/btn-arrow--bright.svg") no-repeat;
  background-size: 2.2rem 1.6rem;
  background-position: 0.8rem 1.1rem;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}

.hidden {
  overflow: hidden;
}

.article-content {
  font-size: 1.4rem;
  line-height: 1.4;
  font-weight: 400;
  color: #787d8e;
}
.article-content h1 {
  font-size: 36px;
  line-height: 1.1;
  font-weight: 500;
  color: #0f1012;
  margin-top: 19px;
  margin-bottom: 38px;
}
@media (min-width: 768px) {
  .article-content h1 {
    font-size: 40px;
  }
}
@media (min-width: 992px) {
  .article-content h1 {
    font-size: 44px;
  }
}
@media (min-width: 1250px) {
  .article-content h1 {
    font-size: 48px;
  }
}
.article-content h2 {
  font-size: 27px;
  line-height: 1.1;
  font-weight: 500;
  color: #0f1012;
  margin-top: 19px;
  margin-bottom: 19px;
}
@media (min-width: 768px) {
  .article-content h2 {
    font-size: 29px;
  }
}
@media (min-width: 992px) {
  .article-content h2 {
    font-size: 33px;
  }
}
@media (min-width: 1250px) {
  .article-content h2 {
    font-size: 37px;
  }
}
.article-content h3 {
  font-size: 25px;
  line-height: 1.1;
  font-weight: 500;
  color: #0f1012;
  margin-top: 19px;
  margin-bottom: 19px;
}
@media (min-width: 768px) {
  .article-content h3 {
    font-size: 27px;
  }
}
@media (min-width: 992px) {
  .article-content h3 {
    font-size: 31px;
  }
}
@media (min-width: 1250px) {
  .article-content h3 {
    font-size: 35px;
  }
}
.article-content h4 {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
  color: #0f1012;
  margin-top: 19px;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .article-content h4 {
    font-size: 19px;
  }
}
@media (min-width: 992px) {
  .article-content h4 {
    font-size: 20px;
  }
}
@media (min-width: 1250px) {
  .article-content h4 {
    font-size: 22px;
  }
}
@media (min-width: 1600px) {
  .article-content h4 {
    font-size: 22px;
  }
}
.article-content h5 {
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500;
  color: #0f1012;
  margin-top: 19px;
  margin-bottom: 6px;
}
@media (min-width: 768px) {
  .article-content h5 {
    font-size: 17px;
  }
}
@media (min-width: 992px) {
  .article-content h5 {
    font-size: 18px;
  }
}
@media (min-width: 1250px) {
  .article-content h5 {
    font-size: 19px;
  }
}
@media (min-width: 1600px) {
  .article-content h5 {
    font-size: 20px;
  }
}
.article-content .subtitle {
  margin-bottom: 3rem;
}
.article-content p, .article-content ul, .article-content ol, .article-content pre, .article-content table, .article-content blockquote {
  margin-top: 0px;
  margin-bottom: 2.5rem;
  font-weight: 400;
}
.article-content p {
  font-size: 1.5rem;
  line-height: 1.3;
  font-weight: 400;
  color: #787d8e;
}
@media (min-width: 1250px) {
  .article-content p {
    font-size: 1.7rem;
    line-height: 1.3;
    font-weight: 400;
  }
}
.article-content ul .article-content ul, .article-content ol .article-content ol, .article-content ul .article-content ol, .article-content ol .article-content ul {
  margin-top: 0;
  margin-bottom: 0;
}
.article-content ul {
  list-style-type: disc;
  margin-left: 40px;
}
.article-content ol {
  list-style-type: decimal;
  margin-left: 40px;
}
.article-content hr {
  border: 1px solid;
  margin: -1px 0;
}
.article-content a, .article-content b, .article-content i, .article-content strong, .article-content em, .article-content small, .article-content code {
  line-height: 0;
}
.article-content sub, .article-content sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
.article-content sup {
  top: -0.5em;
}
.article-content sub {
  bottom: -0.25em;
}
.article-content a {
  color: #d47d50;
}
.article-content img {
  width: 100%;
  border-radius: 22px;
  margin: 35px 0;
  min-height: 25rem;
  object-fit: cover;
}
.article-content img.thumb {
  margin-top: 0;
  margin-bottom: 5rem;
}
@media (min-width: 1250px) {
  .article-content img.thumb {
    margin-bottom: 8rem;
  }
}
.article-content__counter {
  padding: 2rem 0;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  gap: 1rem;
}
@media (min-width: 768px) {
  .article-content__counter {
    flex-direction: row;
  }
}
.article-content__counter-number {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "DM Mono", monospace;
  font-size: 11rem;
  line-height: 1;
  font-weight: 400;
  color: #0f1012;
}
@media (min-width: 768px) {
  .article-content__counter-number {
    font-size: 11rem;
  }
}
.article-content__counter-number::before {
  content: "";
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-color: #d47d50;
}

.form-box {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  border-radius: 24px;
  padding: 25px;
  border: 1px solid #c0c0c0;
}
@media (min-width: 768px) {
  .form-box {
    padding: 25px;
  }
}
.form-box .heading {
  margin-bottom: 0.8px;
}
.form-box .text {
  margin-bottom: 18px;
}
.form-box .label-input {
  display: block;
  color: #082031;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 6px;
}
.form-box .label-option {
  color: #082031;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.form-box .label-option input {
  transform: scale(1.3);
  margin-right: 6px;
}
.form-box .wrap-option {
  margin-bottom: 15px;
}
.form-box .btn {
  font-size: 18px;
}
.form-box .send-ok-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  padding: 60px 0;
  text-align: center;
  background-color: #082031;
}
.form-box .send-ok-info .btn-tel {
  width: max-content;
  margin: 50px auto 0 auto;
  font-weight: 400;
  font-weight: 22px;
  letter-spacing: 0.5px;
}

.list-icon {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.list-icon__item {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 15px;
  font-weight: 300;
  color: #082031;
}
.list-icon__item .bold {
  font-weight: 500;
}
.list-icon__item .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 200px;
  background: #d47d50;
}
.list-icon__item .icon svg {
  max-width: 60%;
}

.container {
  margin: 0 auto;
  padding: 0 1.5rem;
  overflow: hidden;
}
@media (min-width: 1250px) {
  .container {
    max-width: 1100px;
    overflow: unset;
  }
}

.module {
  padding: 4rem 0;
}
.module--pt {
  padding-top: 4rem;
}
.module--pt-2, .page-id-472 .certificates, .page-id-472 .faq, .page-id-468 .faq, .page-id-282 .faq, .page-id-282 .step-to-step, .page-id-109 .local-list, .page-id-109 .step-to-step, .single-case_study .faq {
  padding-top: 8rem;
}
.module--pb, .page-id-15 .local-list, .page-id-109 .local-list {
  padding-bottom: 4rem;
}
.module--pb-2 {
  padding-bottom: 8rem;
}
@media (min-width: 992px) {
  .module {
    padding: 7rem 0;
  }
  .module--pt {
    padding-top: 7rem;
  }
  .module--pt-2, .page-id-472 .certificates, .page-id-472 .faq, .page-id-468 .faq, .page-id-282 .faq, .page-id-282 .step-to-step, .page-id-109 .local-list, .page-id-109 .step-to-step, .single-case_study .faq {
    padding-top: 14rem;
  }
  .module--pb, .page-id-15 .local-list, .page-id-109 .local-list {
    padding-bottom: 7rem;
  }
  .module--pb-2 {
    padding-bottom: 14rem;
  }
}

.hero-contact {
  background-color: #fff;
  padding: 80px 0;
}
@media (max-width: 1250px) {
  .hero-contact .container {
    padding: 0;
  }
}
.hero-contact .grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 60px;
}
@media (min-width: 1250px) {
  .hero-contact .grid {
    grid-template-columns: 1fr 400px;
    justify-content: space-between;
    align-items: center;
    gap: 100px;
  }
}
.hero-contact .header {
  max-width: 500px;
  padding: 0 15px 0 15px;
}
@media (min-width: 1250px) {
  .hero-contact .header {
    padding: 14rem 1.5rem 14rem 1.5rem;
  }
}
@media (max-width: 1250px) {
  .hero-contact__form {
    padding: 0 1.5rem;
  }
}
.hero-contact .rich-text {
  margin-top: 25px;
}

.page-id-109 .range {
  position: relative;
  z-index: 5;
}
@media (max-width: 992px) {
  .page-id-468 .appointment {
    padding-top: 8rem;
  }
}
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}

.glide__slides--dragging {
  user-select: none;
}

.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: initial;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}

.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}

.glide--rtl {
  direction: rtl;
}

.glide__arrow {
  position: absolute;
  display: block;
  top: 50%;
  z-index: 2;
  color: white;
  text-transform: uppercase;
  padding: 9px 12px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
  opacity: 1;
  cursor: pointer;
  transition: opacity 150ms ease, border 300ms ease-in-out;
  transform: translateY(-50%);
  line-height: 1;
}

.glide__arrow:focus {
  outline: none;
}

.glide__arrow:hover {
  border-color: white;
}

.glide__arrow--left {
  left: 2em;
}

.glide__arrow--right {
  right: 2em;
}

.glide__arrow--disabled {
  opacity: 0.33;
}

.glide__bullets {
  position: absolute;
  z-index: 2;
  bottom: 2em;
  left: 50%;
  display: inline-flex;
  list-style: none;
  transform: translateX(-50%);
}

.glide__bullet {
  background-color: rgba(255, 255, 255, 0.5);
  width: 9px;
  height: 9px;
  padding: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  line-height: 0;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  margin: 0 0.25em;
}

.glide__bullet:focus {
  outline: none;
}

.glide__bullet:hover, .glide__bullet:focus {
  border: 2px solid white;
  background-color: rgba(255, 255, 255, 0.5);
}

.glide__bullet--active {
  background-color: white;
}

.glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}