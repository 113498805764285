@use "abstracts/variables" as var;
@use "abstracts/mixins" as mixin;

.form-box {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  border-radius: 24px;
  padding: 25px;

  border: 1px solid #c0c0c0;

  @include mixin.md-up {
    padding: 25px;
  }

  & .heading {
    margin-bottom: 0.8px;
  }

  & .text {
    margin-bottom: 18px;
  }

  & .label-input {
    display: block;
    color: #082031;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 6px;
  }

  & .label-option {
    color: #082031;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;

    & input {
      transform: scale(1.3);
      margin-right: 6px;
    }
  }

  & .wrap-option {
    margin-bottom: 15px;
  }

  & .btn {
    font-size: 18px;
  }

  & .send-ok-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    padding: 60px 0;
    text-align: center;
    background-color: #082031;

    & .btn-tel {
      width: max-content;
      margin: 50px auto 0 auto;
      font-weight: 400;
      font-weight: 22px;
      letter-spacing: 0.5px;
    }
  }
}
