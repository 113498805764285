// ----------------
//     MIXINS
// ----------------

@use "abstracts/variables" as var;

// Media Up

@mixin sm-up {
  @media (min-width: #{var.$sm-break-point}) {
    @content;
  }
}

@mixin md-up {
  @media (min-width: #{var.$md-break-point}) {
    @content;
  }
}

@mixin lg-up {
  @media (min-width: #{var.$lg-break-point}) {
    @content;
  }
}

@mixin xl-up {
  @media (min-width: #{var.$xl-break-point}) {
    @content;
  }
}

@mixin xxl-up {
  @media (min-width: #{var.$xxl-break-point}) {
    @content;
  }
}

// Media Down

@mixin sm-down {
  @media (max-width: #{var.$sm-break-point}) {
    @content;
  }
}

@mixin md-down {
  @media (max-width: #{var.$md-break-point}) {
    @content;
  }
}

@mixin lg-down {
  @media (max-width: #{var.$lg-break-point}) {
    @content;
  }
}

@mixin xl-down {
  @media (max-width: #{var.$xl-break-point}) {
    @content;
  }
}

@mixin xxl-down {
  @media (max-width: #{var.$xxl-break-point}) {
    @content;
  }
}

// Posistion: Absolute

@mixin absolute-center-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin absolute($top: null, $right: null, $bottom: null, $left: null) {
  position: absolute;

  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin relative($top: null, $right: null, $bottom: null, $left: null) {
  position: relative;

  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

// Relative Hidden
@mixin relative-hidden {
  overflow: hidden;
  position: relative;
}

// Font size

@mixin font-size($font-size, $line-height: null, $font-weight: null) {
  font-size: $font-size;
  line-height: $line-height;
  font-weight: $font-weight;
}

// Size

@mixin size($width, $height: null) {
  width: $width;
  height: $height;
}

// Line clamp

@mixin line-clamp($line) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// FLEX

@mixin flex-row($gap) {
  display: flex;
  flex-direction: row;
  gap: $gap;
}

@mixin flex-column($gap) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

@mixin flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-between-end {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

@mixin flex-start-end {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

@mixin flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@mixin flex-center-start {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

@mixin flex-center-end {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

// @mixin flex-center-start {
//     display: flex;
//     justify-content: center;
//     align-items: flex-start;
// }

// @mixin flex-start-center {
//     display: flex;
//     justify-content: flex-start;
//     align-items: center;
// }

// @mixin flex-start-end {
//     display: flex;
//     justify-content: flex-start;
//     align-items: flex-end;
// }

// @mixin flex-end-center {
//     display: flex;
//     justify-content: flex-end;
//     align-items: center;
// }

// @mixin flex-center-end {
//     display: flex;
//     justify-content: center;
//     align-items: end;
// }

@mixin scrollbar($thumb-color, $track-color, $track-size) {
  &::-webkit-scrollbar {
    width: $track-size;
  }

  &::-webkit-scrollbar-track {
    background: $track-color;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: $thumb-color;
    border-radius: 0;
  }

  @supports not (selector(::-webkit-scrollbar)) {
    scrollbar-color: $thumb-color $track-color;
    scrollbar-width: thin;
  }
}
