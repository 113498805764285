// // ----------------
// //     BUTTONS
// // ----------------

@use "abstracts/variables" as var;
@use "abstracts/mixins" as mixin;

.btn {
  display: inline-block;
  position: relative;

  text-decoration: none;
  font-family: var.$main-font-family;
  min-width: fit-content;

  padding: 18px 18px;
  border-radius: var.$basic-btn-radius;
  border: none;
  outline: none;
  cursor: pointer;

  @include mixin.font-size(32px, 1, 600);

  @include mixin.xl-up {
    @include mixin.font-size(var.$basic-font-size, 1, var.$basic-font-weight);
    padding: 21px 27px;
  }

  &--full {
    display: block;
    text-align: center;
    width: 100%;
  }

  &--max-content {
    width: max-content;
  }

  &--bright {
    background-color: var.$bright-btn-bg;
  }

  &--transparent {
    border: 1px solid var.$basic-border-color;
    background-color: none;
    width: max-content;
  }

  &--accent {
    background-color: var.$accent-btn-bg;
  }

  &--text-accent {
    color: var.$accent-font-color;
  }

  &--text-bright {
    color: var.$bright-font-color;
  }

  &--text-dark {
    color: var.$dark-font-color;
  }

  &--arrow {
    padding: 1.8rem 5rem 1.8rem 1.8rem;
    @include mixin.xl-up {
      padding: 2.1rem 6.1rem 2.1rem 2.7rem;
    }

    &::after {
      content: "";
      display: inline-block;
      @include mixin.size(2.9rem, 2.9rem);
      border-radius: 50%;

      @include mixin.absolute(50%, 0.8rem);
      transform: translateY(-50%);

      @include mixin.xl-up {
        @include mixin.size(4rem, 4rem);
      }
    }

    &-bright::after {
      background: #fff url("../img/icons/btn-arrow--accent.svg") no-repeat
        center center;
    }

    &-dark::after {
      background: var.$dark-secendery url("../img/icons/btn-arrow--bright.svg")
        no-repeat center center;
    }

    &-accent::after {
      background: var.$accent-secendery
        url("../img/icons/btn-arrow--bright.svg") no-repeat center center;
    }

    &-w-border::after {
      background: url("../img/icons/btn-arrow--dark.svg") no-repeat center
        center;
      border: 1px solid var.$basic-border-color;

      @include mixin.xl-up {
        background-size: unset;
      }
    }

    &-light {
      background: none;
      padding: 0 4.9rem 0 0;
      line-height: 2.9rem;

      @include mixin.xl-up {
        line-height: 4rem;
        padding: 0 6.5rem 0 0;
      }
    }

    &-left {
      padding: 0 0 0 3.9rem;

      @include mixin.xl-up {
        padding: 0 0 0 5.3rem;
      }

      &::after {
        @include mixin.absolute(50%, null, null, 0);
      }
    }
  }

  &--arrow-only {
    padding: 0;

    @include mixin.size(2.9rem, 2.9rem);

    @include mixin.xl-up {
      @include mixin.size(4rem, 4rem);
    }

    &::after {
      right: 0;
      background-size: 1.4rem;
    }
  }
}

.btn-main-navigation {
  @include mixin.size(3.2rem, 3.2rem);
  background: url("../img/icons/btn-main-navigation.svg") no-repeat center
    center;

  &.open-menu {
    background: url("../img/icons/close-menu.svg") no-repeat center center;
  }
}
