// ----------------
//    VARIABLES
// ----------------

$sm-break-point: 576px;
$md-break-point: 768px;
$lg-break-point: 992px;
$xl-break-point: 1250px;
$xxl-break-point: 1600px;

$container-width-xl: 1100px;

$main-font-family: "Helvetica", sans-serif;
$secendery-font-family: "DM Mono", monospace;

$basic-font-size: 15px;
$basic-line-height: 21px;
$basic-font-weight: 400;

$bright-secendery: #f2f2f4;
$accent-secendery: #d47d50;
$dark-secendery: #0f1012;

$dark-font-color: #0f1012;
$basic-font-color: #787d8e;
$bright-font-color: #fff;
$accent-font-color: #d47d50;

$bright-theme-bg: #f2f2f4;
$basic-theme-bg: #fff;
$dark-theme-bg: #0f1012;
$accent-theme-bg: #d47d50;

$bright-btn-bg: #fff;
$accent-btn-bg: #d47d50;

$basic-btn-radius: 0;

$basic-border-color: #e0d7d5;
$accent-border-color: #d47d50;
$secendery-border-color: #c0c0c0;
