// // ----------------
// //     LINKS
// // ----------------

@use "abstracts/variables" as var;
@use "abstracts/mixins" as mixin;

.link {
  cursor: pointer;

  @include mixin.font-size(1.4rem, 1.4, var.$basic-font-weight);

  @include mixin.xl-up {
    @include mixin.font-size(
      var.$basic-font-size,
      var.$basic-line-height,
      var.$basic-font-weight
    );
  }

  &--dark,
  &--bright,
  &--accent {
    @extend .link;
    transition: 0.3s color;
    &:hover {
      color: var.$accent-font-color;
    }
  }

  &--dark {
    color: var.$dark-font-color;
  }

  &--bright {
    color: var.$bright-font-color;
  }

  &--accent {
    color: var.$accent-font-color;
    text-decoration: underline;
  }

  &-has-children::after {
    content: "";
    display: inline-block;
    @include mixin.size(16px, 16px);
    @include mixin.relative(2px, 0, 0, 7px);

    background: url("../img/icons/link-arrow-black.svg") no-repeat;
  }
}

.back-link {
  @include mixin.flex-row(1rem);
  @include mixin.font-size(1.4rem, 2.3rem, 600);
  color: var.$dark-font-color;

  @include mixin.md-up {
    @include mixin.font-size(1.7rem, 2.8rem, 600);
  }

  &::before {
    content: "";
    display: inline-block;
    transform: rotate(180deg);
    background: var.$accent-btn-bg url("../img/icons/btn-arrow--bright.svg")
      no-repeat center center;
    background-size: 1.2rem 1.2rem;
    border-radius: 5rem;
    @include mixin.size(2.2rem, 2.2rem);

    @include mixin.md-up {
      background-size: 1.4rem 1.4rem;
      @include mixin.size(2.8rem, 2.8rem);
    }
  }
}
